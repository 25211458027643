import React from 'react';
import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';
import AlertIcon from 'asset/imageV2/icon_alert.svg';
const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '28rem',
          textAlign: 'left',
          borderRadius: '20px',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box
          style={{
            fontSize: '1.7rem',
            fontWeight: 'bold',
            marginBottom: '3rem',
            marginTop: '0.5rem',
            display: 'flex',
            gap: '0.5rem',
            alignItems: 'center',
          }}
        >
          필수 조건을 확인하세요!
        </Box>
        <Box
          style={{
            display:
              props.textCountChecked &&
              (props.currentCount < props.textCountMin ||
                props.currentCount > props.textCountMax)
                ? 'flex'
                : 'none',
            alignItems: 'center',
            gap: '0.5rem',
            marginTop: '0.5rem',
          }}
        >
          <img src={AlertIcon} alt="AlertIcon" />
          <span style={{color: 'red'}}>
            {'글자수 : ' + props.currentCount + '자'}
          </span>
          <span>
            {'(조건 : ' +
              props.textCountMin +
              '자 ~ ' +
              props.textCountMax +
              '자)'}
          </span>
        </Box>
        <Box
          style={{
            display:
              props.requiredWordChecked &&
              0 !=
                props.unfoundRequiredWord.split(',').filter(val => val != '')
                  .length
                ? 'flex'
                : 'none',
            alignItems: 'center',
            gap: '0.5rem',
            marginTop: '0.5rem',
          }}
        >
          <img src={AlertIcon} alt="AlertIcon" />
          <span style={{color: 'red'}}>
            {'빠진 키워드 : ' + props.unfoundRequiredWord.replace(',', ', ')}
          </span>
          <span>
            {'(조건 : ' + props.requiredWord.replace(',', ', ') + ')'}
          </span>
        </Box>

        <Box
          style={{
            marginTop: '2rem',
            borderTop: '2px dotted #717171',
          }}
        ></Box>
        <Box style={{marginTop: '1rem', fontWeight: '500'}}>
          조건을 충족할 수 있도록 글을 완성하세요. 조건에 맞지 않는 글은 제출할
          수 없습니다.
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
          }}
        >
          <Button
            style={{
              backgroundColor: '#00C500',
              width: '95%',
              color: 'white',
              fontSize: 'calc(1rem + 0.2vw)',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
            onClick={() => {
              props.cancelEvent();
            }}
          >
            글 수정창으로 돌아가기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  textCountChecked: PropTypes.bool,
  textCountMin: PropTypes.number,
  textCountMax: PropTypes.number,
  currentCount: PropTypes.number,
  requiredWordChecked: PropTypes.bool,
  requiredWord: PropTypes.string,
  unfoundRequiredWord: PropTypes.string,
  cancelEvent: PropTypes.func,
};
export default ItemView;
