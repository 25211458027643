import React, {useEffect, useState, useMemo} from 'react';
import {Box, Grid, TextField, Button, IconButton} from '@mui/material';
import {useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {AnalysisText} from 'view/result/AnalysisText';
import {StringUtils} from 'common/utils/StringUtils';
import {NumberUtils} from 'common/utils/NumberUtils';
import emojiRegex from 'emoji-regex';
import {
  MarginBoxComponent,
  TitleComponent2,
  SingleLineComponent2,
  SingleLineTitle,
  WhiteBox,
} from 'view/common/Components';
import ApiLoading from 'view/common/ApiLoading';
import CommentItem from 'view/comment/CommentItem';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {throttle} from 'lodash';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import SubmitDonePopup from 'view/popup/SubmitDonePopup';
import SubmitInvalidPopup from 'view/popup/SubmitInvalidPopup';
import SubmitLateDonePopup from 'view/popup/SubmitLateDonePopup';
import SubmitLateInvalidPopup from 'view/popup/SubmitLateInvalidPopup';
import RadarChart from 'view/common/RadarChart';
import KeewiCloud from 'view/common/KeewiCloud';
import EssayEditor from 'view/result/EssayEditor';
import PencilIcon2 from 'asset/imageV2/icon_pencil2.svg';
import SubmitIcon from 'asset/imageV2/icon_submit.svg';
import CopyIcon from 'asset/imageV2/icon_copy.svg';

const MainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);

  const [commentParagraph, setCommentParagraph] = useState([]);
  const [highlightWords, setHighlightWords] = useState([]);

  const [loading, setLoading] = useState(true);
  const [onError, setOnError] = useState(false);
  const [currentText, setCurrentText] = useState(location.state.text);
  const [isScrollTop, setIsScrollTop] = useState(true);
  const [bottomOverlay, setBottomOverlay] = useState(true);

  const [taskID, setTaskID] = useState(0);
  const [taskDateLeft, setTaskDateLeft] = useState(0);
  const [taskDueLeft, setTaskDueLeft] = useState(0);
  const [taskDateText, setTaskDateText] = useState('');
  const [taskTitleText, setTaskTitleText] = useState('');
  const [taskMessage, setTaskMessage] = useState('');

  const [submitButtonColor, setsubmitButtonColor] = useState('#00C500');
  const [submitButtonText, setsubmitButtonText] = useState('제출하기');

  const [writingJanre, setWritingJanre] = useState('');
  const [titleText, setTitleText] = useState('');
  const [titleEditable, setTitleEditable] = useState(false);

  const [textCountChecked, setTextCountChecked] = useState(false);
  const [textCountMin, setTextCountMin] = useState('');
  const [textCountMax, setTextCountMax] = useState('');

  const [requiredWordChecked, setRequiredWordChecked] = useState(false);
  const [requiredWord, setRequiredWord] = useState('');
  const [unfoundRequiredWord, setUnfoundRequiredWord] = useState('');

  const [maxEvalTry, setMaxEvalTry] = useState(1);
  const [currentEvalTry, setCurrentEvalTry] = useState(1);
  const [labelText, setLabelText] = useState('');
  const [analyText, setAnalyText] = useState('');
  const [isGPT, setIsGPT] = useState(false);
  const [keewiScore, setKeewiScore] = useState({
    겹치는단어비율: 0,
    단어의평균길이: 0,
    문단별문장수: 0,
    문장별단어수: 0,
    분석오류문장수: 0,
    전체글자수: 0,
    전체문장수: 0,
    전체문단수: 0,
    흐름이자연스러운정도: 0,
    긴문장의수: 0,
    독자나이: 0,
  });

  const [radarData, setRadarData] = useState({
    labels: [],
    datasets: [],
  });

  const [wordCloudData, setWordCloudData] = useState([
    {text: '키위', value: 1},
    {text: '글쓰기', value: 2},
    {text: '평가', value: 3},
    {text: '엔진', value: 4},
    {text: '피드백', value: 5},
  ]);

  const handleTitle = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 100) {
        inputVal = inputVal.substring(0, 100);
      }
      setTitleText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  const [tScore, setTScore] = useState(0);
  const [submitInvalidVisibility, setSubmitInvalidVisibility] = useState(false);
  const [submitDoneVisibility, setSubmitDoneVisibility] = useState(false);
  const [submitLateInvalidVisibility, setSubmitLateInvalidVisibility] =
    useState(false);
  const [submitLateDoneVisibility, setSubmitLateDoneVisibility] =
    useState(false);

  function submitInvalidCancel() {
    setSubmitInvalidVisibility(false);
    setBottomOverlay(false);
  }

  function submitDoneConfirm() {
    setSubmitDoneVisibility(false);
    navigate('/mypage');
  }

  function submitLateDoneConfirm() {
    setSubmitLateDoneVisibility(false);
    navigate('/mypage');
  }

  function submitLateInvalidConfirm() {
    setSubmitLateDoneVisibility(false);
    const this_task_id = taskID;
    const submitResult = async () => {
      try {
        const response = await APIUtils.TaskSubmit(
          authReducer.student_id,
          this_task_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    submitResult().then(res => {
      if (res.status === 200 && res.data.ret_code === 1000) {
        setSubmitDoneVisibility(true);
      }
    });
    navigate('/mypage');
  }

  function submitLateInvalidCancel() {
    setSubmitLateInvalidVisibility(false);
    setBottomOverlay(false);
  }

  function transformWordCloudData(wordCloud) {
    // 원본 데이터의 weight 합계 계산
    const totalWeight = wordCloud
      .slice(0, 40)
      .reduce((sum, item) => sum + item.weight, 0);

    // weight를 100으로 정규화 (스케일링)
    const transformedData = wordCloud.slice(0, 40).map(item => ({
      text: item.name,
      value: (item.weight / totalWeight) * 100,
    }));

    // 변환된 데이터를 설정
    setWordCloudData(transformedData);
  }

  function getTaskInfo(task_id, savedTitle, savedJanre, savedText, fromPrev) {
    const taskResult = async () => {
      try {
        const response = await APIUtils.TaskCall(
          authReducer.student_id,
          task_id,
        );
        if (response.status == 200 && response.data.ret_code == 1000) {
          const dateLeft = StringUtils.getDateDiff(response.data.task_end_time);
          const timeLeft = StringUtils.getTimeDiff(response.data.task_end_time);
          setTaskDateLeft(dateLeft);
          setTaskDueLeft(timeLeft);
          setsubmitButtonColor(timeLeft > 0 ? '#00C500' : 'red');
          setsubmitButtonText(timeLeft > 0 ? '제출하기' : '늦은제출');
          setTaskDateText(
            StringUtils.getDateStringKr(response.data.task_start_time) +
              '~' +
              StringUtils.getDateStringKr(response.data.task_end_time),
          );
          setTaskTitleText(
            '[' + response.data.task_janre + '] ' + response.data.task_name,
          );
          setTaskMessage(response.data.task_message);
          setTextCountChecked(response.data.length_check == 1);
          setTextCountMin(response.data.min_length);
          setTextCountMax(response.data.max_length);
          setRequiredWordChecked(response.data.keyword_check == 1);
          setRequiredWord(response.data.keyword);

          let curText = '';
          if (
            (response.data.title == '' && response.data.text == '') ||
            fromPrev
          ) {
            setMaxEvalTry(response.data.eval_try + 1);
            setCurrentEvalTry(response.data.eval_try + 1);
            curText = savedText;
            analyzeEvent(savedTitle, savedJanre, savedText, task_id, true);
          } else {
            setMaxEvalTry(response.data.eval_try);
            setCurrentEvalTry(response.data.eval_try);
            curText = response.data.text;
            analyzeEvent(
              response.data.title,
              savedJanre,
              response.data.text,
              task_id,
              false,
            );
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    taskResult();
  }

  function setAnalysisInfoFromKeewiApiResult(res, anlayzedText) {
    const keewiScore = {
      겹치는단어비율: NumberUtils.getAnalysisNumber(
        res.data.eval_result.겹치는단어비율 * 100,
      ),
      단어의평균길이: NumberUtils.getAnalysisNumber(
        res.data.eval_result.단어의평균길이,
      ),
      문단별문장수: NumberUtils.getAnalysisNumber(
        res.data.eval_result.문단별문장수,
      ),
      문장별단어수: NumberUtils.getAnalysisNumber(
        res.data.eval_result.문장별단어수,
      ),
      분석오류문장수: res.data.eval_result.분석오류문장수,
      // 전체글자수: textLength,
      전체글자수: res.data.eval_result.전체글자수,
      전체문장수: res.data.eval_result.전체문장수,
      전체문단수: res.data.eval_result.전체문단수,
      흐름이자연스러운정도: NumberUtils.getAnalysisNumber(
        res.data.eval_result.흐름이자연스러운정도 * 100,
      ),
      긴문장의수: res.data.eval_result.긴문장의수 ?? 0,
      독자나이: res.data.eval_result.독자나이 ?? 10,
    };
    setKeewiScore(keewiScore);
    const wholeComment = [
      ...res.data.eval_result.trait_6_comment,
      ...res.data.eval_result.trait_5_comment,
      ...res.data.eval_result.trait_4_comment,
      ...res.data.eval_result.trait_3_comment,
      ...res.data.eval_result.trait_2_comment,
      ...res.data.eval_result.trait_1_comment,
    ];
    setHighlightWords(AnalysisText.createHighlightWordList(wholeComment));
    setCommentParagraph(
      AnalysisText.createComment(
        anlayzedText,
        res.data.eval_result.paragraph_texts,
        wholeComment,
      ),
    );
    setLabelText(AnalysisText.createHtmlTextLabel(res.data.eval_result.type));
    setAnalyText(
      AnalysisText.createHtmlTextAnaly(
        res.data.eval_result.paragraph_texts,
        res.data.eval_result.type,
      ),
    );

    setTScore(NumberUtils.getAnalysisNumber(res.data.eval_result.keewi_score));
    setIsGPT(res.data.eval_result.is_gpt_text);

    let datasets = [];
    datasets = [
      {
        data: [
          res.data.eval_result.trait_1_score,
          res.data.eval_result.trait_2_score,
          res.data.eval_result.trait_3_score,
          res.data.eval_result.trait_4_score,
          res.data.eval_result.trait_5_score,
          res.data.eval_result.trait_6_score,
        ],
        fill: true,
        backgroundColor: ({chart: {ctx}}) => {
          const bg = ctx.createLinearGradient(90, 90, 200, 200);
          bg.addColorStop(0, 'rgba(178, 223, 51, 0.5)');
          bg.addColorStop(1, 'rgba(255, 153, 0, 0.5)');
          return bg;
        },
        borderColor: '#FF9900',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(255, 138, 0, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
    ];
    const newRadarData = {
      labels: ['문법', '어휘', '표현', '문장', '조직', '주제'],
      datasets: datasets,
    };
    setRadarData(newRadarData);
    transformWordCloudData(res.data.eval_result.word_cloud); // 데이터를 변환하고 상태에 설정
  }
  function loadSavedEval(input_task_id, input_eval_try) {
    setLoading(true);
    const loadAnalysisResult = async (task_id, eval_try) => {
      try {
        const response = await APIUtils.TaskResult(
          authReducer.student_id,
          task_id,
          eval_try,
        );
        setAnalysisInfoFromKeewiApiLoad(response);
      } catch (err) {
        console.log(err);
      }
    };
    loadAnalysisResult(input_task_id, input_eval_try).then(r => {
      setLoading(false);
    });
  }
  function setAnalysisInfoFromKeewiApiLoad(res) {
    setTitleText(res.data.eval_result.title);
    setCurrentText(res.data.eval_result.originalText);
    const keewiScore = {
      겹치는단어비율: res.data.eval_result.겹치는단어비율,
      단어의평균길이: res.data.eval_result.단어의평균길이,
      문단별문장수: res.data.eval_result.문단별문장수,
      문장별단어수: res.data.eval_result.문장별단어수,
      분석오류문장수: res.data.eval_result.분석오류문장수,
      전체글자수: res.data.eval_result.전체글자수,
      전체문장수: res.data.eval_result.전체문장수,
      전체문단수: res.data.eval_result.전체문단수,
      흐름이자연스러운정도: res.data.eval_result.흐름이자연스러운정도,
      긴문장의수: res.data.eval_result.긴문장의수 ?? 0,
      독자나이: res.data.eval_result.독자나이 ?? 10,
    };
    setKeewiScore(keewiScore);

    setHighlightWords(
      AnalysisText.createHighlightWordListFromSaved(
        res.data.eval_result.commentList,
      ),
    );

    setCommentParagraph(
      AnalysisText.createCommentFromSaved(
        res.data.eval_result.originalText,
        res.data.eval_result.commentList,
      ),
    );
    setLabelText(AnalysisText.createHtmlTextLabel(res.data.eval_result.type));

    setAnalyText(
      AnalysisText.createHtmlTextAnaly(
        res.data.eval_result.paragraph_texts,
        res.data.eval_result.type,
      ),
    );

    setTScore(res.data.eval_result.score);
    setIsGPT(res.data.eval_result.is_gpt_text);

    let datasets = [];
    datasets = [
      {
        data: [
          AnalysisText.getNumericScore(res.data.eval_result.trait_1_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_2_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_3_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_4_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_5_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_6_score),
        ],
        fill: true,
        backgroundColor: ({chart: {ctx}}) => {
          const bg = ctx.createLinearGradient(90, 90, 200, 200);
          bg.addColorStop(0, 'rgba(178, 223, 51, 0.5)');
          bg.addColorStop(1, 'rgba(255, 153, 0, 0.5)');
          return bg;
        },
        borderColor: '#FF9900',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(255, 138, 0, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
    ];
    const newRadarData = {
      labels: ['문법', '어휘', '표현', '문장', '조직', '주제'],
      datasets: datasets,
    };
    setRadarData(newRadarData);
    transformWordCloudData(res.data.eval_result.word_cloud); // 데이터를 변환하고 상태에 설정
  }

  function analyzeEvent(
    new_title,
    new_janre,
    new_text,
    new_task_id,
    flag_save,
  ) {
    const trimmedText = StringUtils.getTrimmedBody(new_text);
    setLoading(true);
    setCurrentText(new_text);
    setTitleText(new_title);
    setWritingJanre(new_janre);
    const fetAnalasisResult = async (
      inputText,
      inputTitle,
      inputJanre,
      inputTaskID,
    ) => {
      try {
        const response = await APIUtils.TaskEval(
          authReducer.student_id,
          inputTaskID,
          inputTitle,
          inputJanre,
          inputText,
        );
        return response;
      } catch (err) {
        console.log(err);
        setOnError(true);
      }
    };
    const saveResult = async result => {
      try {
        const response = await APIUtils.TaskEvalSave(
          authReducer.student_id,
          new_task_id,
          result,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    fetAnalasisResult(trimmedText, new_title, new_janre, new_task_id)
      .then(res => {
        if (res.data.ret_code == 1000) {
          setAnalysisInfoFromKeewiApiResult(res, trimmedText);
          const jsonResult = AnalysisText.createSavingJsonFromEvalResult(
            res.data.eval_result,
            new_title,
            trimmedText,
            '성인',
            authReducer.student_name,
          );
          if (flag_save) {
            return saveResult(jsonResult);
          }
        }
        setLoading(false);
        return;
      })
      .then(r => {
        if (r == undefined) {
          return;
        }
        if (r.data.ret_code == 1000) {
          localStorage.removeItem('practiceTitle');
          localStorage.removeItem('practiceBody');
          localStorage.removeItem('practiceJanre');
          localStorage.removeItem('taskTitle' + new_task_id);
          localStorage.removeItem('taskBody' + new_task_id);
        }
        setLoading(false);
      });
  }

  function evalSubmit(new_task_id) {
    const isLate = taskDueLeft <= 0; // 마감 여부 확인
    const textLength = StringUtils.getBodyTextLenth(currentText); // 글자 수 확인
    const missingKeywords = requiredWord
      .split(',')
      .filter(keyword => !currentText.includes(keyword) && keyword !== '');

    if (isLate) {
      if (
        (textCountChecked &&
          (textLength < textCountMin || textLength > textCountMax)) ||
        (requiredWordChecked && missingKeywords.length > 0)
      ) {
        // 늦은 제출에서 필수 조건이 충족되지 않음
        setSubmitLateInvalidVisibility(true);
        return;
      } else {
        // 늦은 제출에서 필수 조건이 충족됨
        setSubmitLateDoneVisibility(true);
        const submitResult = async () => {
          try {
            const response = await APIUtils.TaskSubmit(
              authReducer.student_id,
              new_task_id,
            );
            return response;
          } catch (err) {
            console.log(err);
          }
        };
        submitResult().then(res => {
          if (res.status === 200 && res.data.ret_code === 1000) {
            setSubmitLateDoneVisibility(true);
          }
        });
        return;
      }
    } else {
      if (
        (textCountChecked &&
          (textLength < textCountMin || textLength > textCountMax)) ||
        (requiredWordChecked && missingKeywords.length > 0)
      ) {
        setSubmitInvalidVisibility(true);
        return;
      } else {
        setSubmitDoneVisibility(true);
        const submitResult = async () => {
          try {
            const response = await APIUtils.TaskSubmit(
              authReducer.student_id,
              new_task_id,
            );
            return response;
          } catch (err) {
            console.log(err);
          }
        };
        submitResult().then(res => {
          if (res.status === 200 && res.data.ret_code === 1000) {
            setSubmitDoneVisibility(true);
          }
        });
        return;
      }
    }
  }

  function textCopy() {
    const text = currentText || ''; // currentText가 정의되지 않으면 빈 문자열 사용
    const title = titleText || '';
    const currentDate = new Date();
    const formattedDate =
      currentDate.getFullYear() +
      '년 ' +
      String(currentDate.getMonth() + 1).padStart(2, '0') +
      '월 ' +
      String(currentDate.getDate()).padStart(2, '0') +
      '일';
    const copyText =
      '날짜 : ' +
      formattedDate +
      '\n제목 : ' +
      title +
      '\n본문 : \n' +
      text +
      '\n';
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          alert('글이 복사되었습니다.'); // props 대신 alert로 결과 알림
        })
        .catch(() => {
          alert('복사를 다시 시도해주세요.');
        });
    } else {
      // 대체 흐름: execCommand 사용
      if (!document.queryCommandSupported('copy')) {
        alert('복사하기가 지원되지 않는 브라우저입니다.');
        return;
      }
      const textarea = document.createElement('textarea');
      textarea.value = copyText;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        alert('글이 복사되었습니다.');
      } catch (err) {
        alert('복사를 다시 시도해주세요.');
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }

  function createWholeStatisticsText() {
    let flowLevel = '어려움';
    if (keewiScore.흐름이자연스러운정도 >= 71) flowLevel = '쉬움';
    else if (keewiScore.흐름이자연스러운정도 >= 63) flowLevel = '보통';
    return (
      <Box style={{width: '90%', marginBottom: '3rem'}}>
        <Box>
          {createStatisticsText('전체 글자 수', keewiScore.전체글자수, '자')}
        </Box>
        <Box>
          {createStatisticsText('전체 문단 수', keewiScore.전체문단수, '개')}
        </Box>
        <Box>
          {createStatisticsText('전체 문장 수', keewiScore.전체문장수, '개')}
        </Box>
        <Box>
          {createStatisticsText(
            '문단별 문장 수',
            keewiScore.문단별문장수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText(
            '긴 문장(50자)의 수',
            keewiScore.긴문장의수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText('예상 독자 나이', keewiScore.독자나이, '세')}
        </Box>
        <Box>{createStatisticsText('읽기 쉬운 정도', flowLevel, '')}</Box>
      </Box>
    );
  }
  function createStatisticsText(title, score, rest) {
    return (
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '0.4rem',
          fontSize: '1rem',
          fontWeight: '500',
        }}
      >
        <Box>{title}</Box>
        <Box>
          <span style={{color: '#f67f10'}}>{score}</span> {rest}
        </Box>
      </Box>
    );
  }
  function showSaveOverlay() {
    if (bottomOverlay) {
      return (
        <Box
          style={{
            // width: '100%',
            paddingBottom: '2rem',
            position: 'fixed',
            bottom: 0,
            right: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            zIndex: 3,
          }}
        >
          <IconButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#279EFF',
              border: 'solid 2px #279EFF',
              width: '5rem',
              height: '5rem',
              color: 'white',
              fontSize: '0.8rem',
            }}
            onClick={() => {
              setBottomOverlay(false);
            }}
          >
            <img src={PencilIcon2} alt="PencilIcon2" />
            수정하기
          </IconButton>
          <IconButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: submitButtonColor,
              border: 'solid 2px {submitButtonColor}',
              width: '5rem',
              height: '5rem',
              color: 'white',
              fontSize: '0.8rem',
              marginTop: '1rem',
            }}
            onClick={() => {
              evalSubmit(taskID);
            }}
          >
            <img src={SubmitIcon} alt="SubmitIcon" />
            {submitButtonText}
          </IconButton>
          <IconButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#FF9900',
              border: 'solid 2px #FF9900',
              width: '5rem',
              height: '5rem',
              color: 'white',
              fontSize: '0.8rem',
              marginTop: '1rem',
            }}
            onClick={() => {
              textCopy();
            }}
          >
            <img src={CopyIcon} alt="CopyIcon" />
            복사하기
          </IconButton>
        </Box>
      );
    }
    return (
      <EssayEditor
        currentText={currentText}
        analyzeEvent={bodyText => {
          analyzeEvent(titleText, writingJanre, bodyText, taskID, true);
          setBottomOverlay(true);
          setMaxEvalTry(prev => {
            setCurrentEvalTry(prev + 1);
            return prev + 1;
          });
        }}
        cancelEvent={() => {
          setBottomOverlay(true);
        }}
        highlightWordList={highlightWords}
      />
    );
  }
  function showGPTValidation() {
    if (isGPT) {
      return (
        <Box
          style={{
            width: '100%',
            backgroundColor: '#f5d7da',
            color: '#bb0000',
            fontSize: '1rem',
            padding: '0.5rem',
            textAlign: 'center',
          }}
        >
          ⚠ GPT로 생성한 글일 가능성이 높습니다.
        </Box>
      );
    }
  }
  function showFooter() {
    if (bottomOverlay) {
      return <FooterView />;
    }
  }
  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY > 500) {
          setIsScrollTop(false);
        } else {
          setIsScrollTop(true);
        }
      }, 1),
    [isScrollTop],
  );
  useEffect(() => {
    setUnfoundRequiredWord(
      requiredWord
        .split(',')
        .filter(val => !currentText.includes(val))
        .join(','),
    );
  }, [currentText, requiredWord]);
  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll); //clean up
    };
  }, [throttledScroll]);
  useEffect(() => {
    let savedText = '';
    let savedTitle = '';
    let savedJanre = '';
    let savedTaskID = 0;
    if (location.state != null) {
      savedTaskID = location.state.task_id;
    } else {
      savedTaskID = Number(window.localStorage.getItem('task_id'));
    }
    if (location.state == null && window.localStorage.getItem('text') == null) {
      navigate('/', {replace: true});
      return;
    } else if (window.localStorage.getItem('text') != null) {
      savedTitle = window.localStorage.getItem('title');
      savedText = window.localStorage.getItem('text');
      savedJanre = window.localStorage.getItem('janre');
      getTaskInfo(savedTaskID, savedTitle, savedJanre, savedText, false);
    } else {
      savedTitle = location.state.title;
      savedText = location.state.text;
      savedJanre = location.state.writing_janre;

      window.localStorage.setItem('title', location.state.title);
      window.localStorage.setItem('text', location.state.text);
      window.localStorage.setItem('janre', location.state.writing_janre);
      window.localStorage.setItem('task_id', location.state.task_id);
      getTaskInfo(savedTaskID, savedTitle, savedJanre, savedText, true);
    }
    setTaskID(savedTaskID);
  }, []);
  // if (onError) return <ServerError />;
  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <SubmitDonePopup
        visibility={submitDoneVisibility}
        completeEvent={submitDoneConfirm}
      />
      <SubmitInvalidPopup
        visibility={submitInvalidVisibility}
        textCountChecked={textCountChecked}
        textCountMin={Number(textCountMin)}
        textCountMax={Number(textCountMax)}
        currentCount={StringUtils.getBodyTextLenth(currentText)}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
        unfoundRequiredWord={unfoundRequiredWord}
        cancelEvent={submitInvalidCancel}
      />
      <SubmitLateDonePopup
        visibility={submitLateDoneVisibility}
        completeEvent={submitLateDoneConfirm}
      />
      <SubmitLateInvalidPopup
        visibility={submitLateInvalidVisibility}
        textCountChecked={textCountChecked}
        textCountMin={Number(textCountMin)}
        textCountMax={Number(textCountMax)}
        currentCount={StringUtils.getBodyTextLenth(currentText)}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
        taskID={Number(taskID)}
        unfoundRequiredWord={unfoundRequiredWord}
        submitLateInvalidCancel={submitLateInvalidCancel}
        submitLateInvalidConfirm={submitLateInvalidConfirm}
      />
      <HeaderView />
      <TitleComponent2
        dateLeft={taskDateLeft}
        dateLeftText={
          taskDueLeft < 0 ? `D+${Math.abs(taskDateLeft)}` : `D-${taskDateLeft}`
        }
        dateLeftColor={taskDueLeft < 0 ? 'red' : `green`}
        dueLeft={taskDueLeft}
        dateText={taskDateText}
        titleText={taskTitleText}
        messageText={taskMessage}
        textCountChecked={textCountChecked}
        textCountMin={textCountMin}
        textCountMax={textCountMax}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
      />
      <Box style={{marginTop: '5rem'}}></Box>
      {showGPTValidation()}
      <MarginBoxComponent>
        <>
          <Box
            style={{
              borderBottom: '1px solid #7d7d7d',
              marginTop: '2rem',
              marginBottom: '3rem',
              paddingBottom: '1rem',
            }}
          >
            <SingleLineComponent2
              stepNumber="02"
              text={'AI 피드백을 참고하여 글을 수정하세요.'}
              currentIndex={currentEvalTry}
              maxIndex={maxEvalTry}
              leftClickEvent={() => {
                setCurrentEvalTry(prev => {
                  if (prev > 1) {
                    loadSavedEval(taskID, prev - 1);
                    return prev - 1;
                  }
                });
              }}
              rightClickEvent={() => {
                setCurrentEvalTry(prev => {
                  if (prev < maxEvalTry) {
                    loadSavedEval(taskID, prev + 1);
                    return prev + 1;
                  }
                });
              }}
            />
            <SingleLineTitle
              title={titleText}
              tScore={tScore}
              handleInput={handleTitle}
              editable={titleEditable}
              changeEditStatus={status => {
                setTitleEditable(status);
              }}
            />
          </Box>

          <Grid container spacing={'1rem'}>
            <Grid
              item
              xs={7}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  fontSize: '1.375rem',
                  fontWeight: '700',
                  marginBottom: '1.5rem',
                }}
              >
                내용 분석
              </Box>
              <WhiteBox>
                <>
                  {labelText}
                  {analyText}
                </>
              </WhiteBox>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  fontSize: '1.375rem',
                  fontWeight: '700',
                  marginBottom: '1.5rem',
                }}
              >
                글 통계 자료
              </Box>
              <WhiteBox>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    height: '100%',
                  }}
                >
                  {createWholeStatisticsText()}
                  <Box
                    style={{
                      width: '100%',
                      minHeight: '20rem',
                      backgroundColor: '#F7F8FA',
                      borderRadius: '1.25rem',
                      padding: '1.25rem 0rem',
                      marginBottom: '2rem', // 간격 추가
                    }}
                  >
                    <RadarChart radarData={radarData} />
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      minHeight: '20rem',
                      backgroundColor: '#F7F8FA',
                      borderRadius: '1.25rem',
                      padding: '1.25rem 0rem',
                      alignItems: 'center',
                      justifyContent: 'center', // 중앙 정렬
                    }}
                  >
                    <KeewiCloud data={wordCloudData} />
                  </Box>
                </Box>
              </WhiteBox>
            </Grid>
          </Grid>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              marginTop: '3rem',
            }}
          >
            {commentParagraph.map((paragraph, paragraphIndex) => {
              if (paragraph.length <= 2) {
                return;
              }
              if (paragraph.length == 3 && paragraphIndex == 1) {
                return;
              }
              return paragraph.map((value, idx) => {
                return (
                  <CommentItem
                    key={'commentbox' + paragraphIndex + '_' + idx}
                    paragraphIndex={paragraphIndex}
                    idx={idx}
                    commentID={value.commentID}
                    type={value.type}
                    A={value.A}
                    B={value.B}
                    C={value.C}
                    subtype={value.subtype}
                    text={value.text}
                  />
                );
              });
            })}
          </Box>
        </>
      </MarginBoxComponent>
      {showSaveOverlay()}
      {showFooter()}
    </Box>
  );
};
export default MainPage;
