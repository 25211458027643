import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {
  MarginBoxComponent,
  TitleComponent3,
  SingleLineComponent3,
} from 'view/common/Components';
import ApiLoading from 'view/common/ApiLoading';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ClipboardCopyOverlay from 'view/common/ClipboardCopyOverlay';
import ToastPopup from 'view/popup/ToastPopup';
import {StringUtils} from 'common/utils/StringUtils';

const MainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);

  const [loading, setLoading] = useState(true);
  const [taskID, setTaskID] = useState(0);
  const [currentEvalTry, setCurrentEvalTry] = useState(0);
  const [maxEvalTry, setMaxEvalTry] = useState(0);

  const [titleText, setTitleText] = useState('');
  const [bodyText, setBodyText] = useState('');

  const [taskDateText, setTaskDateText] = useState('');
  const [taskDueText, setTaskDueText] = useState('');
  const [taskTitleText, setTaskTitleText] = useState('');
  const [taskMessage, setTaskMessage] = useState('');
  const [evalScore, setEvalScore] = useState(0);

  const [textCountChecked, setTextCountChecked] = useState(false);
  const [textCountMin, setTextCountMin] = useState('');
  const [textCountMax, setTextCountMax] = useState('');

  const [requiredWordChecked, setRequiredWordChecked] = useState(false);
  const [requiredWord, setRequiredWord] = useState('');

  const [toastText, setToastText] = useState('');
  const [toastVisibility, setToastVisibility] = useState(false);

  function handleCopyResult(text) {
    setToastText(text);
    setToastVisibility(true);
    setTimeout(() => {
      setToastVisibility(false);
    }, 2500);
  }

  function getTaskSavedInfo(task_id, eval_try) {
    const taskResult = async () => {
      try {
        const response = await APIUtils.TaskHistoryEvalResult(
          authReducer.student_id,
          task_id,
          eval_try,
        );
        if (response.status == 200 && response.data.ret_code == 1000) {
          setTaskDateText(StringUtils.getDateStringKr(response.data.eval_time));
          setTaskDueText(
            StringUtils.getTimeStringKr(response.data.task_start_time) +
              ' ~ ' +
              StringUtils.getTimeStringKr(response.data.task_end_time),
          );
          setEvalScore(response.data.score);
          setTaskTitleText(
            '[' + response.data.task_janre + '] ' + response.data.task_name,
          );
          setTaskMessage(response.data.task_message);
          setTitleText(response.data.title);
          setBodyText(response.data.text);
          setTextCountChecked(response.data.length_check == 1);
          setTextCountMin(response.data.min_length);
          setTextCountMax(response.data.max_length);
          setRequiredWordChecked(response.data.keyword_check == 1);
          setRequiredWord(response.data.keyword);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    taskResult();
  }
  useEffect(() => {
    let inputTaskID = 0;
    let inputEvalTry = 0;
    if (
      location.state == null &&
      window.localStorage.getItem('task_id') == null
    ) {
      navigate('/', {replace: true});
      return;
    } else if (location.state == null) {
      inputTaskID = Number(window.localStorage.getItem('task_id'));
      inputEvalTry = Number(window.localStorage.getItem('eval_try'));
    } else {
      inputTaskID = location.state.task_id;
      inputEvalTry = location.state.eval_try;
      window.localStorage.setItem('task_id', location.state.task_id);
      window.localStorage.setItem('eval_try', location.state.eval_try);
    }
    setCurrentEvalTry(inputEvalTry);
    setMaxEvalTry(inputEvalTry);
    setTaskID(inputTaskID);
    getTaskSavedInfo(inputTaskID, inputEvalTry);

    const backspace = e => {
      if (
        e.keyIdentifier == 'U+0008' ||
        e.keyIdentifier == 'Backspace' ||
        e.keyCode == 8
      ) {
        if (e.target == document.body) {
          e.preventDefault();
          navigate('/mypage', {
            replace: true,
            state: {
              fragmentNum: 4,
            },
          });
          return false;
        }
      }
    };
    window.addEventListener('keydown', backspace, true);
    const preventGoBack = () => {
      history.pushState(null, '', location.href);

      navigate('/mypage', {
        replace: true,
        state: {
          fragmentNum: 4,
        },
      });
    };
    history.pushState(null, '', location.href);
    window.addEventListener('popstate', preventGoBack);
    return () => {
      window.removeEventListener('keydown', backspace, true);
      window.removeEventListener('popstate', preventGoBack);
    };
  }, []);
  // if (onError) return <ServerError />;
  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <ToastPopup text={toastText} visibility={toastVisibility} />
      <HeaderView />
      <TitleComponent3
        dueText={taskDueText}
        dateText={taskDateText}
        titleText={taskTitleText}
        messageText={taskMessage}
        textCountChecked={textCountChecked}
        textCountMin={textCountMin}
        textCountMax={textCountMax}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
      />
      <MarginBoxComponent>
        <>
          <Box style={{marginTop: '6rem', display: 'flex'}}>
            <Box style={{marginRight: '1rem'}}>{taskDateText}</Box>
            <Box style={{fontWeight: 'bold'}}>{evalScore + ' 점'}</Box>
          </Box>
          <SingleLineComponent3
            marginTop={'1rem'}
            text={titleText}
            currentIndex={currentEvalTry}
            maxIndex={maxEvalTry}
            leftClickEvent={() => {
              setCurrentEvalTry(prev => {
                if (prev > 1) {
                  getTaskSavedInfo(taskID, prev - 1);
                  return prev - 1;
                }
              });
            }}
            rightClickEvent={() => {
              setCurrentEvalTry(prev => {
                if (prev < maxEvalTry) {
                  getTaskSavedInfo(taskID, prev + 1);
                  return prev + 1;
                }
              });
            }}
          />
          <Box
            style={{
              whiteSpace: 'pre-wrap',
              marginTop: '1.5rem',
              lineHeight: '2rem',
              fontSize: '1.2rem',
              marginBottom: '3rem',
              gap: '0.8rem',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {bodyText.split('\n').map((paragraph, idx) => {
              return <span key={'paragraph' + idx}>{paragraph}</span>;
            })}
          </Box>
        </>
      </MarginBoxComponent>
      <ClipboardCopyOverlay
        copyText={
          '날짜 : ' +
          taskDateText +
          '\n제목 : ' +
          titleText +
          '\n본문 : \n' +
          bodyText
        }
        resultEvent={handleCopyResult}
      />
      <FooterView />;
    </Box>
  );
};
export default MainPage;
