import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, Box, FormControl, Button, TextField} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import bullet from 'asset/image/icon_snow.png';
import arrow from 'asset/imageV2/icon_arrow_down.svg';
import {StringUtils} from 'common/utils/StringUtils';

export const MarginBoxComponent = props => {
  return (
    <Box
      style={{
        marginTop: '2%',
        marginBottom: '5%',
      }}
    >
      {props.children}
    </Box>
  );
};
MarginBoxComponent.propTypes = {
  children: PropTypes.element,
};

export const TitleComponent = props => {
  const [expand, setExpand] = useState(false);
  return (
    <Box>
      <Box style={{padding: '1rem', color: '#717171', fontWeight: 'bold'}}>
        {props.title}
      </Box>
      <Box
        style={{
          backgroundColor: '#DEFFDE',
          padding: '1rem',
          fontSize: '0.9rem',
        }}
      >
        <Box
          style={{
            maxHeight: expand ? '' : '4rem',
            overflow: expand ? '' : 'hidden',
          }}
        >
          {props.message}
        </Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            paddingTop: '1rem',
          }}
          onClick={() => {
            setExpand(prev => !prev);
          }}
        >
          <img
            src={arrow}
            alt="arrow"
            style={{
              height: '1.2rem',
              marginTop: '0.1rem',
              marginRight: '0.3rem',
              rotate: expand ? '180deg' : '0deg',
            }}
          />
          {expand ? '더 보기' : '접기'}
        </Box>
      </Box>
    </Box>
  );
};
TitleComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

export const BookComponent = props => {
  return (
    <Box style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
      <Box
        style={{
          width: '100%',
          minHeight: '50px',
          backgroundColor: 'white',
          // borderRadius: '1.25rem',
          // border: '1px solid var(--01-gray-07, #E9EBED)',
          // boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
          padding: '2rem 1rem',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
BookComponent.propTypes = {
  children: PropTypes.element,
};

export const LayerBoxComponent = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          fontWeight: 'bold',
          color: 'black',
          marginLeft: '0.5rem',
        }}
      >
        {props.title}
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        {props.children}
      </Box>
    </FormControl>
  );
};
LayerBoxComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};
export const LayerBoxComponent2 = props => {
  return (
    <FormControl
      fullWidth
      style={{
        backgroundColor: Palette.green1,
        borderRadius: 10,
        height: '100%',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            marginTop: '0.5rem',
            marginLeft: '1rem',
            marginBottom: '0.5rem',
            marginRight: '0.5rem',
          }}
        >
          <img src={bullet} alt={'bullet'} style={{width: '1.5rem'}} />
        </Box>
        <Box
          style={{
            flex: 1,
            fontSize: '1.2rem',
            fontWeight: 'bold',
            color: Palette.white,
          }}
        >
          {' '}
          {props.title}
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: Palette.white,
          margin: 3,
          padding: '2rem',
          borderRadius: 10,
          height: '100%',
          overflow: 'auto',
        }}
      >
        {props.children}
      </Box>
    </FormControl>
  );
};
LayerBoxComponent2.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};
export const LayerBoxComponent3 = props => {
  return (
    <FormControl
      fullWidth
      style={{
        border: '3px solid' + Palette.orange1,
        backgroundColor: Palette.orange1,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          marginLeft: '1rem',
        }}
      >
        <img src={bullet} alt={'bullet'} style={{height: '25px'}} />
        <Box
          style={{
            fontSize: 'calc(0.6rem + 0.8vw)',
            fontWeight: 'bold',
            color: Palette.white,
            marginLeft: '0.5rem',
          }}
        >
          {props.title}
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
          backgroundColor: Palette.white,
        }}
      >
        {props.children}
      </Box>
    </FormControl>
  );
};
LayerBoxComponent3.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};
export const BorderBoxComponent = props => {
  return (
    <Box
      style={{
        boxSizing: 'border-box',
        borderRadius: 10,
        border: '3px solid ' + Palette.green1,
        height: '100%',
        marginLeft: '2rem',
      }}
    >
      {props.children}
    </Box>
  );
};
BorderBoxComponent.propTypes = {
  children: PropTypes.element,
};
const disabledStyles = theme => ({
  disabledButton: {
    backgroundColor: 'red',
  },
});
export const CenteredButton = props => {
  return (
    <Box
      style={{
        marginTop: '2rem',
        marginBottom: '2rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button
        variant="filled"
        // disabled={props.disabled}
        // classes={{disabled: disabledStyles.disabledButton}}
        style={{
          backgroundColor: props.disabled ? '#7c7c7c' : '#dcf0c7',
          borderRadius: 10,
          paddingLeft: '3rem',
          paddingRight: '3rem',
          fontWeight: 'bold',
          fontSize: '1.2rem',
          boxShadow: '0px 5px 10px 2px rgba(0, 0, 0, 0.1)',
        }}
        onClick={() => {
          props.clickEvent(props.disabled);
        }}
      >
        {props.title}
      </Button>
    </Box>
  );
};
CenteredButton.propTypes = {
  title: PropTypes.string,
  clickEvent: PropTypes.func,
  disabled: PropTypes.bool,
};

export const SingleLineComponent = props => {
  return (
    <Box
      style={{
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        marginTop: '1rem',
      }}
    >
      <Box
        style={{
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: '#ff9900',
          padding: '0.3rem 0.75rem',
          borderRadius: '6.25rem',
          marginRight: '0.625rem',
          fontSize: '0.8rem',
        }}
      >
        {'STEP ' + props.stepNumber}
      </Box>
      <Box style={{fontSize: '1rem', fontWeight: 'bold', color: '#2f3941'}}>
        {props.text}
      </Box>
    </Box>
  );
};
SingleLineComponent.propTypes = {
  stepNumber: PropTypes.string,
  text: PropTypes.string,
};
export const CustomTextField1 = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
          }}
        >
          {props.title}{' '}
          <span
            style={{display: props.isRequired ? '' : 'none', color: '#35D2BB'}}
          >
            *
          </span>
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            height: '3.3rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },

              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={props.inputText == '' ? props.emptyText : ''}
          InputLabelProps={{
            style: {
              color: Palette.grey1,
              fontSize: '1rem',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          value={props.inputText}
          onChange={props.handleInput}
        ></TextField>
      </Box>
    </FormControl>
  );
};
CustomTextField1.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  inputText: PropTypes.string,
  handleInput: PropTypes.func,
  isRequired: PropTypes.bool,
};
export const CustomTextField2 = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
          }}
        >
          {props.title} <span style={{color: '#00C500'}}>*</span>
        </Box>
      </Box>
      <Box
        style={{
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            height: '3.3rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },

              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={props.inputText == '' ? props.emptyText : ''}
          InputLabelProps={{
            style: {
              color: Palette.grey1,
              fontSize: '1rem',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          value={props.inputText}
          onChange={props.handleInput}
        ></TextField>
      </Box>
      <Box style={{textAlign: 'end', fontSize: '0.875rem'}}>
        <span style={{color: '#00C500'}}>
          {StringUtils.getBodyTextLenth(props.inputText)}
        </span>
        <span style={{color: '#A8B1B7'}}>/{props.maxCount}</span>
      </Box>
    </FormControl>
  );
};
CustomTextField2.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  inputText: PropTypes.string,
  handleInput: PropTypes.func,
  maxCount: PropTypes.string,
};
export const CustomTextField3 = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
          }}
        >
          {props.title} <span style={{color: '#00C500'}}>*</span>
        </Box>
      </Box>
      <Box
        style={{
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        <TextField
          autoComplete="off"
          multiline
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },
              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={props.inputText == '' ? props.emptyText : ''}
          InputLabelProps={{
            style: {
              color: Palette.grey1,
              fontSize: '1rem',
              whiteSpace: 'pre-wrap',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          rows={20}
          value={props.inputText}
          onChange={props.handleInput}
        ></TextField>
      </Box>
      <Box style={{textAlign: 'end', fontSize: '0.875rem'}}>
        <span style={{color: '#00C500'}}>
          {StringUtils.getBodyTextLenth(props.inputText)}
        </span>
        <span style={{color: '#A8B1B7'}}>/{props.maxCount}</span>
      </Box>
    </FormControl>
  );
};
CustomTextField3.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  inputText: PropTypes.string,
  handleInput: PropTypes.func,
  maxCount: PropTypes.string,
};
